import React, { useCallback, useEffect, useState } from "react";

import Aries from "../../locale/en-Us.json";
import { decoderJsonV2 } from "../../stepesJsons/decoder";
import { Button, Card } from "antd";
import { FileUploader } from "react-drag-drop-files";

import images from "../../components/images/images";

import { DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import ButtonGroupFooter from "../../components/buttonGroupFooter";
import { downloadBase64File } from "../../utils/utils";
import { apiBaseUrl } from "../../components/api/api";

const TrialBalanceUpload = ({ backStep, nextStep, projectId, sheetTemplate }) => {
  const [fileData, setFileData] = useState(null);

  const handleChange = useCallback((files) => {
    uploadFile(files);
  }, []);

  const uploadFile = async (files) => {
    const formData = new FormData();
    formData.append(`file`, files);
    try {
      const response = await fetch(
        `${apiBaseUrl}/v1/decoder/${projectId}/trial-balance/upload`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("userData")).token}`,
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
      } else {
        const fileData = await response.json();
        setFileData(fileData?.[0]);
      }
    } catch (error) {
      console.error("Error in file upload:", error.message);
    }
  };

  const buttonGroup = {
    left: [],
    right: [
      {
        text: Aries.BUTTON_GLOBAL_BACK,
        //disabled: isLoading,
        className: "back-button",
        fn: backStep,
        imageLink: images["arrow-left.svg"],
      },
      {
        text: Aries.BUTTON_GLOBAL_NEXT,
        //disabled: isLoading || disabledNextButton,
        disabled: !fileData,
        className: "primary-button",
        fn: nextStep,
        rightImageLink: images["arrow-right.svg"],
      },
    ],
  };

  return (
    <div className="decoder-trial-balance-upload">
      <div className="body">
        <Card className="upload-card">
          {fileData ? (
            <div className="upload-box">
              <div className={"file-added"}>
                <span style={{ wordWrap: "break-word" }}>
                  <PaperClipOutlined /> {fileData?.originalFileName}
                </span>
                <div className="">
                  <DeleteOutlined
                    onClick={async () => {
                      setFileData(null);
                      //await deleteUploadFile(fileData?.bucketFileId);
                    }}
                  />
                </div>
              </div>

              <FileUploader
                types={["xlsx"]}
                handleChange={handleChange}
                name="file"
                classes="full-width"
                multiple
                // disabled
              >
                <button
                  className="primary-button button full-width"
                  // disabled
                >
                  {Aries.PROGRAMS_BROWSE}
                </button>
              </FileUploader>
            </div>
          ) : (
            <FileUploader
              types={["xlsx"]}
              handleChange={handleChange}
              name="file"
              classes="upload-box"
            >
              <>
                <img
                  src={images["file-upload-box-ixon.svg"]}
                  alt="uploadicon"
                  className="upload-icon"
                />
                <h3>{Aries.PROGRAMS_FILE_UPLOAD_TITLE}</h3>
                <p>{Aries.PROGRAMS_FILE_UPLOAD_XLSX_SUBTITLE}</p>
              </>
              <button
                className="primary-button button full-width upload-button"
                // disabled={!editAllowed}
              >
                {Aries.PROGRAMS_BROWSE}
              </button>
            </FileUploader>
          )}
        </Card>

        <Card className="template-download-card">
          <div className="template-download">
            <image src={images["file-template.svg"]} alt="File" />

            <p className="heading">Download Sample Document</p>
            <p className="sub-heading">
              Download this sample document and upload file in same format.
            </p>
            <Button
              className="button download-button"
              onClick={() => downloadBase64File(sheetTemplate)}
            >
              Download
            </Button>
          </div>
        </Card>
      </div>

      <ButtonGroupFooter
        buttonGroupObject={buttonGroup}
        className={"fixed-button-group"}
      />
    </div>
  );
};

export default TrialBalanceUpload;
